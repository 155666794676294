<template>
  <label class="switch">
    <input type="checkbox" v-model="checked">
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: "TheToggleSwitch",
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      checked: false,
    }
  },
  mounted() {
    if (this.value) this.checked = this.value;
  },
  watch: {
    checked(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.switch {
  position: relative;
  display: inline-block;
  min-width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 1px rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: $primary-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>