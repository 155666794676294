<template>
  <section>
    <dl class="final-data-list">
      <div class="list-item">
        <dt class="key">{{$t('agreementDate')}}</dt>
        <dd class="value">{{ info.start_date | dateFormat }}</dd>
      </div>
      <div class="list-item">
        <dt class="key">{{$t('phoneNumber')}}</dt>
        <dd class="value">{{ info.phone_number | phoneMasked }}</dd>
      </div>
      <div class="list-item">
        <dt class="key">{{$t('fullName')}}</dt>
        <dd class="value">{{ info.fullname }}</dd>
      </div>
      <div class="list-item" v-if="info.bonus_product">
        <dt class="key">{{$t('bonusProduct')}}</dt>
        <div class="text-right">
          <dd class="value">{{ bonusProductName }}</dd>
          <dd class="value" v-if="info.autoPlus && info.autoPlus.name">{{ info.autoPlus.name }}</dd>
        </div>
      </div>
      <div class="list-item" v-else-if="insurance_type == '1000'">
         <dt class="key">{{$t('bonusProduct')}}</dt>
         <div class="text-right">
           <dd class="value">Не предусмотрено</dd>
         </div>
      </div>
      <div class="list-item">
        <p>
          <i18n path="agreeWithTerms" tag="span" for="declaration" class="text">
            <span style="color: #2aa65c" @click="showDeclarationModal">{{ $t('declaration') }}</span>

            <span style="color: #2aa65c" @click="showInsuranceRulesModal">{{ $t('rules') }}</span>
          </i18n>
<!--          <i18n path="readInsuranceTerms" tag="span" for="rules" class="text">-->
<!--            <span style="color: #2aa65c" @click="showInsuranceRulesModal">{{ $t('rules') }}</span>-->
<!--          </i18n>-->
        </p>
        <the-toggle-switch :value="termsAgree" @input="$emit('changeTermsAgree', $event)"/>
      </div>
    </dl>
  </section>
</template>

<script>
import TheToggleSwitch from "./TheToggleSwitch";
import DeclarationModal from "./Modals/DeclarationModal";
import InsuranceRulesModal from "./Modals/InsuranceRulesModal";
import {mapGetters} from "vuex";

export default {
  name: "FormConfirmInfo",
  props: {
    termsAgree: {
      type: Boolean,
      default: true,
    },
    info: null,
    insurance_type: null
  },

  components: {
    TheToggleSwitch
  },
  methods: {
    showDeclarationModal() {
      this.$modal.show(DeclarationModal, {insurance_type: this.INSURANCE_PRODUCT.insrType},this.$modalConfig)
    },
    showInsuranceRulesModal() {
      this.$modal.show(InsuranceRulesModal, {insurance_type: this.INSURANCE_PRODUCT.insrType},this.$modalConfig)
    }
  },
  computed: {
    ...mapGetters(['selectedLanguage', 'INSURANCE_PRODUCT']),
    bonusProductName(){
      return this?.info?.bonus_product?.name
    }
  }
}
</script>

<style scoped>
.text-right{
  text-align: right;
}
</style>
