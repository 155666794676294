<template>
  <modal-layout @close="$emit('close')" :title="$t('productRules')">
    <div class="p-3">
      <ul>
        <li class="mb-2" v-for="rule in rules" :key="rule.id">
          <a :href="rule.url" target="_blank" style="color: #2aa65c">— {{ rule.name }}</a>
        </li>
      </ul>
     </div>
    <button class="primary-btn bottom-page-btn" @click="$emit('close')">{{$t('close')}}</button>
  </modal-layout>
</template>

<script>
export default {
  name: "InsuranceRulesModal",
  props: {
    insurance_type: null
  },
  computed:{
    rules() {
      const product = this.$store.getters['INSURANCE_PRODUCT'];

      if(!product) { return [] }

      if(localStorage.getItem('lang') === 'kk') {
        return product.rules_kz || []
      } else if (localStorage.getItem('lang') === 'en') {
        return product.rules_en || []
      } else {
        return product.rules
      }
    }
  },
  methods: {
    async downloadAgreement(url) {
      let loader = this.$loading.show({});

      window.axios
          .get(url, {
            responseType: 'arraybuffer'
          })
          .then(response => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'file.pdf');

            document.body.appendChild(fileLink);

            fileLink.click();
          }).then(() => loader.hide())
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
