<template>
  <modal-layout @close="$emit('close')" :title="$t('declarationTitle')">
    <div class="p-3">
      <div v-html="declaration" class="injected-html"></div>
    </div>
    <button class="primary-btn bottom-page-btn" @click="$emit('close')">{{$t('close')}}</button>
  </modal-layout>
</template>

<script>
export default {
  name: "DeclarationModal",
  computed: {
    declaration() {
      const product = this.$store.getters['INSURANCE_PRODUCT'];
      
      return product?.declaration;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
