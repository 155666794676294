<template>
  <div>
    <div class="input-field" :class="{'block': isBlock}" v-if="paymentMethods.length > 0">
      <ul class="input-list">
        <li class="input-list-item" @click="isSelectOptionsShown = !isSelectOptionsShown">
          <div class="d-flex align-items-center">
            <div class="icon-box me-3">
              <icon :icon-name="cardIcon"></icon>
            </div>
            <span class="primary-text">{{ activeOption ? activeOption.maskedPan : '' }}</span>
          </div>
          <div>
            <icon icon-name="select-arrow-icon" class="arrow-down" :class="{'inverted': isSelectOptionsShown}"></icon>
          </div>
        </li>
      </ul>
      <ul class="options-list" :class="{'active': isSelectOptionsShown}">
        <template v-for="(item, index) in paymentMethods">
          <li class="options-list-item" @click="selectOption(item)" v-if="activeOption !== item" :key="index">
            {{ item.maskedPan }}
          </li>
        </template>
      </ul>
    </div>
    <div class="primary-block" v-else>
      <div class="d-flex align-items-center">
        <div class="icon-box me-3">
          <icon icon-name="plus-icon"></icon>
        </div>
        <span class="primary-text">{{$t('newCard')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditCardSelect",
  props: {
    value: null,
    isBlock: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paymentMethods: [],
      selected: null,
      isSelectOptionsShown: false,
      activeOption: ''
    }
  },
  async mounted() {
    await this.getPaymentMethods();

    if (this.paymentMethods.length > 0) {
      this.activeOption = this.paymentMethods[0]
    }
    if (this.value) {
      this.selected = this.value;
    } else if (this.paymentMethods.length > 0) {
      this.selected = this.paymentMethods[0].cardId
    }
  },
  computed: {
    cardIcon() {
      if(this.activeOption.type === 'VISA') {
        return 'visa-icon'
      }
      else if (this.activeOption.type === 'MASTERCARD') {
        return 'master-card-icon'
      }
      else {
        return 'wallet-icon'
      }
    }
  },
  methods: {
    async getPaymentMethods() {
      const {data} = await window.axios.get('/payment-methods');

      if(data.length > 0){
        this.paymentMethods = [...data, {cardId: null, id: null, isDefault: false, maskedPan: this.$i18n.t('payAnotherCard'), type: null, userId: null}];
      }
    },
    selectOption(item) {
      this.activeOption = item;
      this.isSelectOptionsShown = false;
    }
  },
  watch: {
    activeOption(value) {
      this.$emit('input', value);
    },
    value(newValue) {
      this.activeOption = newValue;
    },
  }
}
</script>

<style scoped>

</style>
